import React, { useEffect, useState } from 'react'
import { Button, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Avatar, Box, Modal, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import useAuth from '@/Hooks/useAuth'
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel'
import { IconChevronLeft, IconChevronRight, IconCircleCheck } from '@tabler/icons-react'
import { router } from '@inertiajs/react'
import useWidthResize from '@/Hooks/useWidthResize'
import { getInitials } from '@/helpers'

interface TaskAndFollowUpPopUpModalProps {
  data: {
    type: string
    item: {
      lead?: {
        id: string
        name: string
        phone: string
      }
      assignees?: App.Models.User[]
      follow_up?: string
      description?: string
      created_by?: App.Models.User
      due_date?: string
      id: string
      is_completed: boolean
      due_for_humans: string
      updated_at_for_humans: string
    }
  }[]
}

const TaskAndFollowUpPopUpModal = () => {
  const auth = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const [data, setData] = React.useState<TaskAndFollowUpPopUpModalProps | null>(null)
  const TRANSITION_DURATION = 200
  const [embla, setEmbla] = useState<Embla | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(data?.data.length)
  const width = useWidthResize()

  useAnimationOffsetEffect(embla, TRANSITION_DURATION)

  useEffect(() => {
    window.Echo.private(`App.Models.User.${auth.user.id}`).listen(
      '.task.followup',
      (data: TaskAndFollowUpPopUpModalProps) => {
        if (document.visibilityState === 'hidden') {
          return
        }

        setData(data)
        open()
      }
    )

    return () => {
      window.Echo.private(`App.Models.User.${auth.user.id}`).stopListening('.task.followup')
    }
  }, [])
  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        const newIndex = embla.selectedScrollSnap()
        setCurrentIndex(newIndex)
        setTotalCount(data?.data.length - newIndex) // update count on every slide click
      })
    }
  }, [embla])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={600}
      opened={opened}
      onClose={close}
      padding={0}
      transitionProps={{ duration: TRANSITION_DURATION }}
    >
      <VStack style={{ position: 'relative' }}>
        <HStack justifyContent={'space-between'}>
          <HStack
            justifyContent={'flex-start'}
            alignItems={'center'}
            style={{
              position: 'absolute',
              top: '20px',
              width: '60px',
              right: '60px',
              display: 'flex',
            }}
          >
            <Typography color={XelaColor.Blue3} variant={'body-small'} style={{ fontWeight: 700 }}>
              {totalCount} more
            </Typography>
          </HStack>
          <HStack
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '40px',
              display: 'flex',
              zIndex: '1',
              border: '1px solid',
              borderColor: XelaColor.Gray11,
              padding: '8px',
            }}
            justifyContent={'center'}
            borderRadius="12px"
          >
            {currentIndex > 0 && (
              <IconChevronLeft
                width={16}
                height={16}
                color={XelaColor.Blue3}
                onClick={(event) => {
                  event.stopPropagation()
                  embla?.scrollPrev()
                }}
              ></IconChevronLeft>
            )}
            <IconChevronRight
              width={16}
              height={16}
              color={XelaColor.Blue3}
              onMouseDown={(event) => {
                event.stopPropagation()
                embla?.scrollNext()
              }}
            ></IconChevronRight>
          </HStack>
        </HStack>

        <Carousel
          align="start"
          getEmblaApi={setEmbla}
          withControls={false}
          styles={{
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default',
              },
              top: 0,
              transform: 'translateX("-50%")',
              'z-index': 1,
            },
          }}
        >
          {data?.data.map((item) => (
            <Carousel.Slide key="title">
              <VStack spacing="16px" style={{ padding: '16px', position: 'relative' }}>
                <HStack alignItems={'flex-start'}>
                  <Typography variant={'body-bold'} style={{ fontWeight: 800 }} onClick={close}>
                    {item.type}
                  </Typography>
                  {item.type === 'task' && !item.item.lead && (
                    <HStack justifyContent={'flex-end'}>
                      <ChipX
                        label={item.item.due_for_humans}
                        deactiveBg={XelaColor.Red12}
                        deactiveLabelColor={XelaColor.Red2}
                      ></ChipX>
                    </HStack>
                  )}
                </HStack>
                <HStack>
                  <Divider variant="dotted"></Divider>
                </HStack>
                {item.item.lead ? (
                  <HStack justifyContent={'space-between'}>
                    <VStack>
                      <Typography variant={'body-small-bold'}>{item.item.lead.name}</Typography>
                      <Typography variant={'body-small'} style={{ fontWeight: 600 }}>
                        {item.item.lead.phone}
                      </Typography>
                    </VStack>
                    <HStack justifyContent={'flex-end'}>
                      <ChipX
                        label={item.item.due_for_humans}
                        deactiveBg={XelaColor.Red12}
                        deactiveLabelColor={XelaColor.Red2}
                      ></ChipX>
                    </HStack>
                  </HStack>
                ) : null}

                <HStack>
                  {item.type === 'task' ? (
                    <Typography variant={'body-bold'} style={{ fontWeight: 600 }}>
                      {item.item.description}
                    </Typography>
                  ) : (
                    <Typography variant={'body-small-bold'} style={{ fontWeight: 600 }}>
                      {item.item.follow_up}
                    </Typography>
                  )}
                </HStack>
                <HStack>
                  <Typography variant={'body-small'} style={{ fontWeight: 600 }} color={XelaColor.Gray6}>
                    Created by
                  </Typography>
                  <Typography variant={'body-small'} style={{ fontWeight: 600 }} color={XelaColor.Blue3}>
                    {item.item.created_by?.name}
                  </Typography>
                  <Typography variant={'body-small'} style={{ fontWeight: 600 }} color={XelaColor.Gray6}>
                    at {item.item.updated_at_for_humans}
                  </Typography>
                  {item.item.assignees && (
                    <HStack spacing="4px" justifyContent={'space-between'}>
                      <Tooltip.Group openDelay={200} closeDelay={100}>
                        <Avatar.Group spacing={'sm'}>
                          {item.item.assignees.slice(0, 3).map((assignee, index) => {
                            return (
                              <Tooltip
                                key={assignee.id}
                                position="top-start"
                                label={auth.user.id === assignee.id ? assignee.name + ' (You)' : assignee.name}
                                withArrow
                              >
                                <Avatar
                                  className="mr-1.5"
                                  variant="filled"
                                  radius="xl"
                                  color={`${index % 3 === 0 ? 'orange.3' : index % 3 === 1 ? 'pink.3' : 'green.2'}`}
                                  size={width > 1280 ? 30 : 24}
                                >
                                  <Typography
                                    style={{ fontSize: width > 1280 ? '11px' : '9px' }}
                                    color={
                                      index % 3 === 0
                                        ? XelaColor.Orange1
                                        : index % 3 === 1
                                        ? XelaColor.Red1
                                        : XelaColor.Green1
                                    }
                                  >
                                    {getInitials(assignee.first_name, assignee.last_name)}
                                  </Typography>
                                </Avatar>
                              </Tooltip>
                            )
                          })}
                          {item.item.assignees.length > 3 ? (
                            <>
                              <Tooltip
                                position="right"
                                withArrow
                                label={item.item.assignees.map((item, index) => (
                                  <Box key={index}>{item.name}</Box>
                                ))}
                              >
                                <Box className={'flex items-end pb-1'}>
                                  <Typography
                                    as={'span'}
                                    variant={'sub-caption-bold'}
                                    className={'h-fit cursor-pointer'}
                                    color={XelaColor.Blue3}
                                  >
                                    + Show more
                                  </Typography>
                                </Box>
                              </Tooltip>
                            </>
                          ) : null}
                        </Avatar.Group>
                      </Tooltip.Group>
                    </HStack>
                  )}
                </HStack>
                <HStack>
                  <Divider variant="dotted"></Divider>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Button
                    label={'Open'}
                    size={'small'}
                    variant={'secondary'}
                    color={XelaColor.Blue3}
                    onClick={() => {
                      if (item.type === 'task') {
                        router.visit(`/leads/${item.item.lead?.id}?show_tab=tasks`)
                      } else {
                        router.visit(`/leads/${item.item.lead?.id}?show_tab=follow-up`)
                      }
                    }}
                  />
                  <Button
                    label={'Mark Complete'}
                    style={{ padding: '16px' }}
                    leftIcon={<IconCircleCheck width={15} height={15}></IconCircleCheck>}
                    variant={'secondary'}
                    size={'small'}
                    color={XelaColor.Blue3}
                    onClick={() => {
                      if (item.type === 'task') {
                        axios
                          .post(route('tasks.mark-complete', { id: item.item.id }), {
                            is_completed: true,
                          })
                          .then(() => {
                            router.reload()
                          })
                      } else {
                        axios
                          .post(route('follow-ups.mark-complete', { id: item.item.id }), {
                            is_completed: true,
                          })
                          .then(() => {
                            router.reload()
                          })
                      }
                    }}
                  />
                </HStack>
              </VStack>
            </Carousel.Slide>
          ))}
        </Carousel>
      </VStack>
    </Modal>
  )
}

export default TaskAndFollowUpPopUpModal
